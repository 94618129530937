import { LineV2 } from '@sixriver/fulfillment-api-schema';
import { IndexTable } from '@sixriver/lighthouse-web-community';

import { NoData } from '../../../components/NoData';
import { useWorkAreas } from '../../../hooks/useWorkAreas';

interface Props {
	lines: { node: LineV2 }[];
}

export function WorkAreaCell({ lines }: Props) {
	const { data: allWorkAreas } = useWorkAreas();
	const uniqueWorkAreaNames = lines.reduce((workAreas, { node: line }) => {
		if (line.workArea?.id) {
			const workArea = allWorkAreas.find((workArea) => workArea.mapChunkId === line.workArea?.id);
			if (workArea) {
				workAreas.add(workArea.displayName ?? workArea.mapChunkId);
			}
		}
		return workAreas;
	}, new Set<string>());

	return (
		<IndexTable.Cell>
			{uniqueWorkAreaNames.size > 0 ? (
				<div>
					{Array.from(uniqueWorkAreaNames)
						.map((areaName) => areaName)
						.join(', ')}
				</div>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
