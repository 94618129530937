import { useEffect, useRef, useState } from 'react';

import { parseTelemetryData, Telemetry } from './Telemetry';

/**
 * Interval in ms to throttle telemetry events to.  Lowering this too far may
 * lead to performance issues in sites with many chucks or on computers with
 * lower specs.
 */
const EVENT_INTERVAL = 250;

/**
 * This hook listens for messages on the Map Manager's Socket.IO endpoint and
 * returns all telemetry events received during at a set interval. (It does NOT use the
 * Socket.IO client library.) This data is then merged with the known list of
 * devices provided by the Fulfillment API.
 */
export function useTelemetry(): { telemetry: Telemetry[]; connected: boolean } {
	const [telemetry, setTelemetry] = useState<Telemetry[]>([]);
	const [connected, setConnected] = useState(true);
	const ping = useRef<NodeJS.Timeout>();
	const url = String(import.meta.env.VITE_FLOOR_VIEW_SOCKET_URL);

	useEffect(() => {
		let socket: WebSocket;

		// ping the server every 20 seconds to keep the socket open
		const startPing = () => {
			ping.current = setInterval(() => {
				if (socket.readyState === socket.OPEN) {
					socket.send('2');
				}
			}, 20000);
		};

		const stopPing = () => {
			if (ping.current) {
				clearInterval(ping.current);
			}
		};
		let eventList: Telemetry[] = [];

		// try to reconnect when re-entering the view
		const onVisibilityChange = () => {
			if (document.visibilityState === 'visible') {
				if (socket.readyState !== socket.OPEN) {
					connectSocket();
				}
			}
		};

		const connectSocket = () => {
			socket = new WebSocket(url.replace('{HOSTNAME}', window.location.hostname));

			socket.onmessage = (evt: MessageEvent) => {
				const messageTypeId = evt.data.match(/\d+/)[0];

				// sample message: '42["message",{"data":{"id":"mfp-debug","name": ...'
				if (messageTypeId === '42') {
					const response = JSON.parse(evt.data.substr(2) as string);
					const payload = response[1];
					const telemetry = parseTelemetryData(payload.data);

					if (telemetry.x && telemetry.y) {
						eventList.push(telemetry);
					}
				}
			};

			socket.onopen = () => {
				setConnected(true);
			};

			socket.onclose = (evt: CloseEvent) => {
				// https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent/code
				if (![1000, 1005].includes(evt.code)) {
					// try to reconnect immediately if the socket closed unexpectedly.
					setConnected(false);
					connectSocket();
				}
			};

			// this should close the connection, which we already handle
			// socket.onerror = () => {
			// 	throw new Error();
			// };

			stopPing();
			startPing();
		};

		connectSocket();

		document.addEventListener('visibilitychange', onVisibilityChange);

		const interval = setInterval(() => {
			if (eventList.length > 0) {
				setTelemetry(eventList);
				eventList = [];
			}
		}, EVENT_INTERVAL);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);

			if (socket) {
				socket.close();
			}

			stopPing();
			clearInterval(interval);
		};
	}, [url]);

	return { connected, telemetry };
}
